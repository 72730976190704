/* eslint-disable no-self-compare */
/* eslint-disable no-self-compare */
/* eslint-disable default-case */
/* eslint-disable no-restricted-syntax */
<template>
  <b-card
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"

    title="Lista Posts"
  >
    <b-modal
      v-model="modalCreatePosts"
      title="Crear Posts"
      ok-title="Crear"
      cancel-title="Cerrar"
      size="m"
      @ok="crear"
    >
      <b-form-group
        id="formgroup"
        label="type"
        label-for="basicInput"
      >
        <b-form-input
          id="basicInput"
          v-model="type"
          placeholder="Ingresa Type"
        />
      </b-form-group>

      <b-form-group
        id="formgroup"
        label="descripcion"
        label-for="desc"
      >
        <b-form-input
          id="desc"
          v-model="descripcionPost"
          placeholder="Ingresa Descripcion"
        />
      </b-form-group>

      <!--b-form-group
          label="fecha"
          label-for="desc"
        >
        <flat-pickr
            v-model="dateNtim"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
          />
        </b-form-group-->
    </b-modal>
    <b-modal
      id="modalEliminarPosts"
      v-model="modalEliminarPosts"
      title="Eliminar Posts"
      ok-title="Sí"
      cancel-title="No"
      size="m"
      @ok="eliminarPosts"
    >
      Estas apunto de eliminar un Posts, ¿Estas seguro?
    </b-modal>

    <b-modal
      id="modalActualizarPosts"
      v-model="modalActualizarPosts"
      title="Actualizar Posts"
      ok-title="Sí"
      cancel-title="No"
      size="m"
      @ok="actualizarPosts"
    >
      Posts ID : {{ selectedPostUpdate._id }}

      <!--b-form-group
          id="formgroup"
          label="nombre"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            v-model="nombrePost"
            placeholder="Ingresa nombre"
          />
        </b-form-group-->

      <b-form-group
        label="Status"
        label-for="statusselec"
      >
        <b-form-select
          id="statusselec"
          v-model="status"
          :options="options"
        />
      </b-form-group>

      <!--b-form-group
          id="formgroup"
          label="descripcion"
          label-for="desc"
        >
          <b-form-input
            id="desc"
            v-model="descripcionPost"
            placeholder="Ingresa Descripcion"
          />
        </b-form-group>

        <b-form-group
          label="fecha"
          label-for="desc"
        >
        <flat-pickr
            v-model="dateNtim"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
          />
        </b-form-group-->

    </b-modal>
    <b-form-group
      label="fecha desde"
      label-for="desc"
    >
      <b-form-datepicker
        id="example-datepicker"
        v-model="selectedDate"
        class="mb-2"
        locale="es-CL"
        @context="changeDate"
      />
    </b-form-group>
    <b-form-group
      label="fecha hasta"
      label-for="desc"
    >
      <b-form-datepicker
        id="example-datepicker"
        v-model="selectedDateFin"
        class="mb-2"
        locale="es-CL"
        @context="changeDate"
      />
    </b-form-group>
    <b-form-group>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn btn-primary"
        variant="primary"
        @click="filtroPosts"
      >
        Filtrar
      </b-button>
    </b-form-group>
    <vue-good-table
      :columns="columns"
      :rows="aggPostsGroup"
      :group-options="{
        enabled: true,
      }"
      :search-options="{
        enabled: true
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'">
          <b-button
            v-if="props.row.Status == 'Reservada'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn btn-primary"
            variant="primary"
            @click="enviarData(props.row)"
          >ver detalles
          </b-button>
        </span>
      </template>
    </vue-good-table>

    <b-modal
      v-model="modalDetalles"
      title="Detalles Turnos"
      cancel-title="Cerrar"
      size="m"
    >
      <!-- b-table
        striped
        hover
        responsive
        :fields="fieldsResumen"
        :items="resumenPost"
        class="mb-0"
        sticky-header="true"
        sticky-column="true"
      >
      <template #cell(reserva)="data">
        {{data.reservation}}
      </template>
      </b-table -->

      <ul class="auto-suggest-suggestions-list">
        <li
          v-for="(item, index) in resultGetResumen"
          :key="index"
        >Epa: {{ item.name_epa }} - Rayo: {{ item.rayo_name }}</li>
      </ul>

    </b-modal>
    <!--label>Filtro:</label>
    <b-form-group
      label="Type:"
      label-for="typeselec"
    >
      <b-form-select
        id="typeselec"
        v-model="filtroType"
      >
        <option
          v-for="item in types"
          :key="item._id.tipo"
          :value="item.name"
        >
          {{ item.name }}
        </option>
      </b-form-select>
    </b-form-group>

    <b-form-group
      id="formgroup"
      label="Status:"
      label-for="status"
    >
      <b-form-select
        id="status"
        v-model="filtroStatus"
        :options="options"
      />
    </b-form-group>

    <b-form-group
      label="fecha"
      label-for="desc"
    >
      <flat-pickr
        v-model="filtroFecha"
        class="form-control"
        :config="{ enableTime: true,dateFormat: 'Y-m-d', mode: 'range'}"
      />
    </b-form-group>

    <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Escribe para buscar"
            ></b-form-input-->
    <!--b-form-group>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn btn-primary"
        variant="primary"
        @click="filtroPosts"
      >
        Filtrar
      </b-button>
    </b-form-group-->
    <!--b-table
      striped
      hover
      responsive
      :items="aggPosts"
      class="mb-0"
      :fields="fieldsPosts"
      sticky-header="true"
      sticky-column="true"
    >
      <template #cell(type)="data">
        {{ data.item._id.tipo }}

      </template>
      <template #cell(fecha)="data">
        {{ data.item._id.fecha }}

      </template>
      <template #cell(action)="data">
        <b-button
          v-b-modal="'modalEliminarPosts'"
          @click="sendInfo(data.item._id)"
        >
          eliminar
        </b-button>
        <b-button
          v-b-modal="'modalActualizarPosts'"
          @click="editInfo(data.item)"
        >
          editar
        </b-button>
      </template>
    </b-table -->
  </b-card>
</template>

<script>
// import store from '@/store'
import {
  BCard,
  // BImg, BMedia, BAvatar,BButton,
  VBModal,
  BFormInput,
  BFormSelect,
  BFormGroup,
  BFormDatepicker,
  // BTable,
  // BButton,
} from 'bootstrap-vue'
// import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import flatPickr from 'vue-flatpickr-component'
// import * as Realm from 'realm-web'
// import { BSON } from 'realm-web'
import * as moment from 'moment-timezone'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
// import { codeRowSelectSupport } from '@/views/table/bs-table/code'
// eslint-disable-next-line no-unused-vars

// eslint-disable-next-line no-unused-vars

// import {
//   // eslint-disable-next-line no-unused-vars
//   ObjectId,
// } from 'mongodb'
// import userStoreModule from '../userStoreModule'

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
    // BTable,
  },
  components: {
    // BButton,
    // BTable,
    BCard,
    BFormInput,
    BFormGroup,
    BFormSelect,
    // flatPickr,
    VueGoodTable,
    BFormDatepicker,
    // BImg,
    // BMedia,
    // BAvatar,
    // AppTimeline,
    // AppTimelineItem,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // app: new Realm.App({ id: 'partnersrayoappcom-ucuyv' }),
      mongodb: {},
      postsDB: {},
      posts: [],
      fields: ['type', 'date', 'desc', 'reservation', 'status'],
      fieldsPosts: ['Type', 'Cantidad', 'Fecha'],
      fieldsResumen: ['Reserva'],
      rayos: [],
      // swiperData: [
      //   { img: this.userData.responses },
      //   { img: this.userData.image_rayo },
      //   { img: this.userData.image_rayo },
      //   { img: this.userData.image_rayo },
      // ],
      columns: [
        {
          label: 'Type',
          field: 'label',
        },
        {
          label: 'Estado',
          field: 'status',
        },
        {
          label: 'Cantidad',
          field: 'count',
          headerField: 10,
          type: 'number',
        },
        {
          label: 'Acciones',
          field: 'action',
        },
      ],
      rows: [],
      imagesFromDocs: [],
      modalCreatePosts: false,
      modalEliminarPosts: false,
      modalActualizarPosts: false,
      dateNtim: null,
      nombrePost: null,
      descripcionPost: null,
      postsResult: null,
      selectedUser: '',
      selectedPostUpdate: '',
      id: '',
      type: '',
      status: '',
      aggPosts: '',
      aggPostsGroup: '',
      filtroType: '',
      types: '',
      filtroStatus: '',
      typeResult: '',
      options: [
        { value: null, text: 'Seleccione ...' },
        { value: 'Disponible', text: 'Disponible' },
        { value: 'Reservada', text: 'Reservada' },
      ],
      optionsType: null,
      filters: {
        type: { value: '', keys: ['type'] },
      },
      filter: null,
      filtroFecha: '',
      fechaArr: [],
      modalDetalles: false,
      resumenPost: '',
      resultGetResumen: [],
      selectedDate: moment(new Date()).format('YYYY-MM-DD'),
      selectedDateFin: moment(new Date()).format('YYYY-MM-DD'),
    }
  },
  // setup() {
  //   const USER_APP_STORE_MODULE_NAME = 'postulaciones'
  //   if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
  // },
  async mounted() {
    this.mongodb = store.getters['app/realmApp'].currentUser.mongoClient('mongodb-atlas')
    // this.postsDB = await store.getters['app/realmApp'].currentUser.functions.readPosts()
    // .log(await store.getters['app/realmApp'].currentUser.functions.readPosts())
    this.postsDB = this.mongodb.db('fleet_manager').collection('posts')
    // console.log(store.getters['app/realmApp'])
    // console.log(
    //  await store.getters['app/realmApp'].currentUser.functions.getRayosPerEPA('5fbd027be49e6c004a1540ef'),
    // )
    // setTimeout(() => { this.selectedDate = this.currentDate() }, 2000)
    // this.findPosts()
    // this.findRayos()
    // this.findType()
    this.setWatchs()
    // this.findTypes()

    this.filtrarFechaResumen(this.selectedDate)
  },
  methods: {
    changeDate() {
      // console.log('changed!!')
      this.filteredPOsts = (this.selectedDate !== '' && this.selectedDate !== undefined && this.selectedDate != null)
        ? this.posts.filter(y => moment(y.date).format('YYYY-MM-DD') === this.selectedDate && y.status === 'Disponible')
        : this.posts
    },
    enviarData(rows) {
      // this.getInfoResumenPost(rows.label)
      this.getInfoResumenPost(rows._id)
      // console.log(rows)
      this.modalDetalles = true
    },
    sumCount(rowObj) {
      // console.log('---------------------------')
      // console.log(rowObj)
      let sum = 0
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < rowObj.children.length; i++) {
        sum += rowObj.children[i].count
      }
      return sum
    },
    async filtrarFechaResumen(fecha, fechaFin) {
      this.aggPostsGroup = ''
      this.aggPostsGroup = await store.getters['app/realmApp'].currentUser.functions.aggAllGroupFilter(fecha, fechaFin)
      if (this.aggPostsGroup.length >= 1) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.aggPostsGroup.length; i++) {
        // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.aggPostsGroup[i].children.length; j++) {
            if (this.aggPostsGroup[i].children[j].Status === 'Disponible' && this.aggPostsGroup[i].children.length < 2) {
              this.aggPostsGroup[i].children.push({ Status: 'Reservada', count: 0 })
            } else if (this.aggPostsGroup[i].children[j].Status === 'Reservada' && this.aggPostsGroup[i].children.length < 2) {
              this.aggPostsGroup[i].children.push({ Status: 'Disponible', count: 0 })
            }
          }
        }
        // console.log(this.aggPostsGroup)
      }
    },
    async  createPosts(type, descripcion) {
      // const result = await this.postsDB.find({ }) // *****????
      this.postsResult = await store.getters['app/realmApp'].currentUser.functions.createPosts(type, descripcion)
      // return result
    },
    async  deletePosts(idPost) {
      // const result = await this.postsDB.find({ }) // *****????
      this.postsResult = await store.getters['app/realmApp'].currentUser.functions.deletePosts(idPost)
      // return result
    },
    async  updatePosts(id, status) {
      // const result = await this.postsDB.find({ }) // *****????
      this.postsResult = await store.getters['app/realmApp'].currentUser.functions.updatePosts(id, status)

      // return result
    },
    /* async  getInfoResumenPost(type) {
      // const result = await this.postsDB.find({ }) // *****????
      this.resumenPost = await store.getters['app/realmApp'].currentUser.functions.getInfoResumenPost(type)
      // console.log(this.resumenPost[0].reservation)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.resumenPost.length; i++) {
        if (this.resumenPost[i].reservation != null) {
          this.resultGetResumen.push(this.resumenPost[i].reservation)
        }
      }
      // return result
    }, */
    async  getInfoResumenPost(id) {
      // const result = await this.postsDB.find({ }) // *****????
      this.resumenPost = await store.getters['app/realmApp'].currentUser.functions.getInfoResumenPost(id)
      /* console.log('-------')
      console.log(id)
      console.log(this.resumenPost)
      console.log('-------') */
      // console.log(this.resumenPost[0].reservation)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.resumenPost.length; i++) {
        if (this.resumenPost[i].reservation != null) {
          this.resultGetResumen.push(this.resumenPost[i].reservation)
        }
      }
      // return result
    },
    async  findTypes() {
      // const result = await this.postsDB.find({ }) // *****????
      this.types = await store.getters['app/realmApp'].currentUser.functions.readType()
      // return result
    },
    async  findType() {
      /* eslint-disable no-plusplus */
      /* eslint no-underscore-dangle: 0 */
      // const result = await this.postsDB.find({ }) // *****????
      this.typeResult = await store.getters['app/realmApp'].currentUser.functions.aggType()
      // return result
    },
    async  findPosts() {
      /* eslint-disable no-plusplus */
      /* eslint no-underscore-dangle: 0 */
      // const result = await this.postsDB.find({ }) // *****????
      this.aggPosts = await store.getters['app/realmApp'].currentUser.functions.aggAll()
      for (let i = 0; i < this.aggPosts.length; i++) {
        this.aggPosts[i]._id.fecha = moment(this.aggPosts[i]._id.fecha).tz('America/Santiago').format('YYYY-MM-HH')
      }
      this.aggPostsGroup = await store.getters['app/realmApp'].currentUser.functions.aggAllGroup()
      for (let i = 0; i < this.aggPostsGroup.length; i++) {
        for (let j = 0; j < this.aggPostsGroup[i].children.length; j++) {
          if (this.aggPostsGroup[i].children[j].Status === 'Disponible' && this.aggPostsGroup[i].children.length < 2) {
            this.aggPostsGroup[i].children.push({ Status: 'Reservada', count: 0 })
          } else if (this.aggPostsGroup[i].children[j].Status === 'Reservada' && this.aggPostsGroup[i].children.length < 2) {
            this.aggPostsGroup[i].children.push({ Status: 'Disponible', count: 0 })
          }
        }
      }
      // console.log('----**************')
      // console.log(this.aggPostsGroup)
      // console.log('----**************')
      // return result
    },
    async  findRayos() {
      // const result = await this.postsDB.find({ }) // *****????
      this.rayos = await store.getters['app/realmApp'].currentUser.functions.getRayosPerEPA('5fbd027be49e6c004a1540ef')
      // return result
    },
    async  aggregationPosts(filtroType, filtroStatus, fechainicio, fechafin) {
      // const result = await this.postsDB.find({ }) // *****????
      this.aggPosts = await store.getters['app/realmApp'].currentUser.functions.aggregationPosts(filtroType, filtroStatus, fechainicio, fechafin)
      // console.log(this.aggPosts)
      // return result
    },

    async  setWatchs() {
      // eslint-disable-next-line no-restricted-syntax
      for await (const change of this.postsDB.watch()) {
        // eslint-disable-next-line no-restricted-syntax
        // eslint-disable-next-line default-case
        switch (change.operationType) {
          case 'insert': {
            // eslint-disable-next-line no-restricted-syntax
            // const { documentKey, fullDocument } = change
            // eslint-disable-next-line no-restricted-syntax
            this.filtrarFechaResumen(this.selectedDate, this.selectedDateFin)
            // console.log(`new document: ${documentKey}`, fullDocument)
            break
          }
          case 'update': {
            // eslint-disable-next-line no-restricted-syntax
            // eslint-disable-next-line no-unused-vars
            const { documentKey, fullDocument } = change
            // eslint-disable-next-line no-underscore-dangle
            // console.log(documentKey._id)
            // console.log({ _id: BSON.ObjectID('61df68791eea470debce4c79') })
            // console.log('9999')
            this.filtrarFechaResumen(this.selectedDate, this.selectedDateFin)
            // eslint-disable-next-line no-underscore-dangle
            // console.log(this.posts.filter(x => x._id.toString() === BSON.ObjectID(documentKey._id).toString()))

            break
          }
          case 'replace': {
            // const { documentKey, fullDocument } = change
            // console.log(`replaced document: ${documentKey}`, fullDocument)
            break
          }
          case 'delete': {
            // const { documentKey } = change
            this.filtrarFechaResumen(this.selectedDate, this.selectedDateFin)
            // console.log(`deleted document: ${documentKey}`)
            break
          }
        }
      }
    },
    // crearPartners(dataIn) {
    //   console.log(dataIn)
    //   store.dispatch('postulaciones/createEpaPartnersServer', dataIn)
    //     .then(response => {
    //       console.log(response)
    //     })
    //     .catch(error => {
    //       if (error.response.status === 404) {
    //         console.log(false)
    //       }
    //     })

    //   return {
    //     a: false,
    //   }
    // },
    getDefinition(id) {
      // console.log(id)
      return this.userData.documents.responses.definition.fields.find(x => x.id === id)
    },
    crear() {
      // console.log(this.dateNtim)
      // console.log(this.descripcionPost)
      // console.log(this.type)

      this.createPosts(this.type, this.descripcionPost)
    },
    eliminarPosts() {
      // console.log(this.selectedUser)
      this.deletePosts(this.selectedUser)
    },
    sendInfo(item) {
      // console.log(item.toHexString())
      this.selectedUser = item.toHexString()
    },
    editInfo(item) {
      // console.log('-----')
      // console.log(item)
      this.selectedPostUpdate = item
      this.dateNtim = this.selectedPostUpdate.date
      this.descripcionPost = this.selectedPostUpdate.desc
      this.nombrePost = this.selectedPostUpdate.test
    },
    actualizarPosts() {
      // eslint-disable-next-line no-param-reassign, no-underscore-dangle
      this.id = this.selectedPostUpdate._id.toHexString()
      this.updatePosts(this.id, this.status)
    },
    filtroPosts() {
      this.filtrarFechaResumen(this.selectedDate, this.selectedDateFin)
    },
  },
}
</script>

<style>
.expan {
  width: 140px;
  max-width: 100%;
}

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
